@import './variables.scss';

h2 {
    font-family: $font-headings;
    margin: map-get($spacers, xl) 0 map-get($spacers, xl);
    text-align: center;
}

h3 {
    font-family: $font-headings;

    * + &,
    & + * {
        margin-top: map-get($spacers, md);
    }
}

h4, h5, h6 {
    * + &,
    & + * {
        margin-top: map-get($spacers, sm);
    }
}

img {
    max-width: 100%;
}

blockquote {
    border: 1px solid;
    border-radius: 10px;
    background-color: map-get($colours, electric-purple);
    font-weight: bold;
    margin: map-get($spacers, lg) auto;
    max-width: 700px;
    padding: map-get($spacers, md);

    figcaption {
        padding-top: map-get($spacers, md);
        text-align: right;
    }
}
