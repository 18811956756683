@import 'https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto+Mono:wght@300&display=swap';

$sizes: (
    0: 'xs',
    1: 'sm',
    2: 'md',
    3: 'lg',
    4: 'xl',
);

$colours: (
    "dark": #1f1f1f,
    "light": #ffffff,

    "electric-purple": #cc00ff,
    "fuchsia": #ff38d1,
    "carolina-blue": #009fe3,
    "vivid-sky-blue": #00ccff,
    "turquoise-blue": #0dfef5,
    "sunglow": #ffcc00,
    "green": #04f114,

);

$spacers: (
    xxs: 0.15rem,
    xs: 0.25rem,
    sm: 0.5rem,
    md: 1rem,
    lg: 2rem,
    xl: 4rem,
    xxl: 6rem,
);

$breakpoints: (
    xs: 512px,
    sm: 768px,
    md: 1200px,
    lg: 1440px,
    xl: 1920px,
);

$whitespace: (
    0: map-get($spacers, 0),
    xs: map-get($spacers, 1),
    sm: map-get($spacers, 2),
    md: map-get($spacers, 3),
    lg: map-get($spacers, 4),
    xl: map-get($spacers, 5),
);

$font-body: 'Roboto Mono', monospace;
$font-headings: 'Press Start 2P', cursive;

$modal-padding: 1rem;
$modal-header-padding: 1rem;
$modal-header-border-bottom: 1px solid;
$modal-header-margin-bottom: 0;
$modal-footer-border-top: 1px solid;
$modal-footer-margin-top: 0;

$z-index-tooltip: 50;
$z-index-modal: 25;
$z-index-header: 20;
$z-index-overlays: 10;
$z-index-raised: 5;