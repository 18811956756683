@import '../scss/variables.scss';

.slide {
    align-items: center;
    background-color: map-get($colours, dark);
    display: flex;
    padding: map-get($spacers, md);
    position: relative;
    text-align: center;
}

.close {
    background: grey;
    border: 1px solid;
    border-radius: 50%;
    color: map-get($colours, light);
    cursor: pointer;
    font-weight: bold;
    height: 2rem;
    padding: 0.25rem;
    position: absolute;
    right: 0.5rem;
    text-align: center;
    top: 0.5rem;
    width: 2rem;
    z-index: $z-index-header;
}

.image {
    margin: auto;
    max-height: 80vh;
    max-width: 100%;
}
