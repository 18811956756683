@import '../scss/variables.scss';

@each $size, $breakpoint in $sizes {
    .stack-#{$size} {
        & > * + * { margin-left: map-get($spacers, $breakpoint); }
    }
    .stack-col-#{$size} {
        & > * + * { margin-top: map-get($spacers, $breakpoint); }
    }
}

.stack-center {
    display: flex;
    justify-content: center;
}
