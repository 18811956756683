@import '../scss/variables.scss';

.modal-container {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
    left: 0;
    overflow-y: auto;
    padding: 2rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-modal;
}

.modal {
    margin: auto;
    padding: $modal-padding;
}

.header,
.footer {
    padding: $modal-header-padding 0;
}

.header {
    border-bottom: $modal-header-border-bottom;
    margin-bottom: $modal-header-margin-bottom;
}

.footer {
    border-top: $modal-footer-border-top;
    margin-top: $modal-footer-margin-top;
}

@each $size,
$value in $breakpoints {
    .size-#{$size} {
        max-width: $value;
        width: 100%;
    }
}