.cruise {
    background-image: url('../../../public/img/crom-tuise.gif');
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -150px;
    cursor: pointer;
    left: -150px;
    height: 150px;
    position: fixed;
    transition: bottom 0.25s linear;
    width: 150px;

    &.greet {
        bottom: 0;
        left: 0;
    }
}