@import '../scss/variables.scss';

@mixin respond-to($breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
    }
}

.page {
    background-color: map-get($colours, carolina-blue);
    color: map-get($colours, light);
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .tom-cruise-mode-banner {
        background-color: map-get($colours, "fuchsia");
        font-size: 2rem;
        padding: map-get($spacers, md);
        text-align: center;
    }

    @keyframes bounce {
        from {
            opacity: 1;
            transform: scale(1);
        }

        to {
            opacity: 0.7;
            transform: scale(1.5);
        }
    }

    .bounce {
        animation: bounce 0.3s infinite;
    }

    .topbar {
        background-color: map-get($colours, dark);
        color: map-get($colours, light);
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: map-get($spacers, sm);
        white-space: pre;
    }

    .header {
        align-items: center;
        background-color: map-get($colours, sunglow);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: map-get($spacers, md) map-get($spacers, lg);
    }

    .content {
        flex-basis: 100%;
        padding: map-get($spacers, md);
        padding-bottom: map-get($spacers, xl);

        .no-gutter {
            padding: 0;
        }
    }

    .footer {
        background-color: map-get($colours, dark);
        color: map-get($colours, light);
        display: flex;
        flex-direction: row;
        padding: map-get($spacers, xxl) map-get($spacers, lg);
    }
}

@each $breakpoint,
$width in $breakpoints {
    .size-#{$breakpoint} {

        .content,
        .footer-content {
            margin: 0 auto;
            max-width: $width;
            width: 100%;
        }
    }
}

// Add breakpoints
body {

    @each $breakpoint-key,
    $breakpoint-value in $breakpoints {
        --bp-#{$breakpoint-key}: #{$breakpoint-value};
    }
}

// Expose breakpoints
body:after {
    position: absolute;
    left: -1337px;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
    opacity: 0;
    font-size: 0;
    z-index: -1;

    @each $breakpoint-key,
    $breakpoint-value in $breakpoints {
        @include respond-to($breakpoint-key) {
            content: '#{$breakpoint-key}';
        }
    }
}

@media (max-width: map-get($breakpoints, md)) {
    .content {
        text-align: center;

        h2 {
            line-height: 2.5rem;
        }
    }

    .footer {
        font-size: small;
        text-align: center;

        .content {
            justify-content: center;
        }

        h3 {
            font-size: small
        }
    }
}