@import '../scss/variables.scss';

.navigation {
    display: flex;

    .link {
        color: map-get($colours, dark);
        cursor: pointer;
        font-weight: bold;
        display: flex;
        justify-content: center;
        padding: map-get($spacers, md);
        text-decoration: none;
        transition: all 0.25s linear;

        .text {
            font-family: $font-headings;
            place-self: center;
        }

        &.active,
        &:hover {
            background-color: map-get($colours, dark);
            color: map-get($colours, light);

            @each $name, $hex in $colours {
                &.#{$name} {
                    background-color: $hex;
                }
            }
        }
    }
}

.backdrop {
    color: map-get($colours, dark);
    z-index: $z-index-overlays;

    &.expanded {
        color: map-get($colours, light);
        background-color: rgba(0, 0, 0, 0.9);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;

        .docked {
            display: flex;
            flex-direction: column;
        }

        .link {
            color: map-get($colours, light);
        }
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
        padding: map-get($spacers, md);
    }

    .docked {
        color: map-get($colours, dark);
        display: none;
        z-index: $z-index-modal;
    }
}