@import '../scss/variables.scss';

.grid {
    display: grid;
}

.place-content-start > * { place-content: flex-start; }
.place-content-end > * { place-content: flex-end; }
@each $place in center, space-between, space-around, space-evenly {
    .place-content-#{$place} {
        place-content: $place;
    }
}

@each $breakpoint, $size in $spacers {
    .gap-#{$breakpoint} {
        grid-gap: $size;
    }
}