@import './variables.scss';

* {
    box-sizing: border-box;
}

html, body {
    font-family: $font-body;
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

ul {
    margin: 0;
    padding: map-get($spacers, md);
}

p {
    margin: 0;
    * + & { margin-top: map-get($spacers, md); }
    & + * { margin-top: map-get($spacers, md); }
}
